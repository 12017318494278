@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

* {
    font-family: Raleway, sans-serif
}

.stylish-text {
    color: #4B515D
}

p {
    font-size: 18px;
}

.custom-hover-tab:hover {
    cursor: pointer;
    font-weight: bold;
}

.custom-hover-tab.active {
    font-weight: bold;
}

@media only screen and (max-width: 1200px) {
    p {
        font-size: 16px;
    }
}